<template>
  <div class="dict" v-loading.fullscreen.lock="loadingDetail">
    <div class="form-header-btn">
      <el-page-header @back="goBack" :content="'目录管理/' + title">
        <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
    </div>
    <el-form
      :model="dialogForm"
      :label-width="labelWidth"
      :rules="rules"
      ref="dialogForm"
      style="width: 80%"
      :disabled="readonly"
    >
      <el-form-item class="form-choose-item" prop="parentId" label="父级节点：">
        <el-cascader
          :show-all-levels="false"
          style="width: 100%"
          v-model="dialogForm.parentId"
          :options="parentList"
          :props="treeProps"
          @change="handleChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item
        class="form-choose-item"
        prop="directoryName"
        label="目录名称："
      >
        <el-col :span="24">
          <el-input
            class="form-input"
            v-model.trim="dialogForm.directoryName"
            clearable
            :maxlength="25"
            placeholder="请输入目录名称"
          />
        </el-col>
      </el-form-item>
      <el-form-item class="form-choose-item" prop="description" label="描 述：">
        <el-col :span="24">
          <el-input
            type="textarea"
            rows="4"
            :maxlength="500"
            placeholder="请输入描述"
            v-model="dialogForm.description"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item class="form-choose-item" prop="sort" label="排 序：">
        <el-col :span="24">
          <el-input
            class="form-input"
            v-model="dialogForm.sort"
            max="10000"
            min="0"
            placeholder="请设置排序"
            type="number"
            v-input-number
            :maxlength="3"
            @keyup.native="getInputChange(dialogForm.sort, 'sort')"
          />
        </el-col>
      </el-form-item>
      <el-form-item class="form-choose-item" prop="isEnable" label="是否启用：">
        <!-- <el-switch
                    v-model="dialogForm.isEnable"
                ></el-switch> -->
        <el-radio-group v-model="dialogForm.isEnable">
          <el-radio label="1">启用</el-radio>
          <el-radio label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        class="form-choose-item"
        prop="visibleType"
        label="目录权限："
      >
        <el-radio-group
          v-model="dialogForm.visibleType"
          @change="handleChangeCategroyQx"
        >
          <el-radio label="1">岗位</el-radio>
          <el-radio label="2">组织</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        class="form-choose-item"
        prop="visibleRange"
        label="可见范围："
      >
        <!-- :show-all-levels="false" -->
        <el-cascader
          style="width: 100%"
          ref="cascader"
          :collapse-tags="true"
          v-model="dialogForm.visibleRange"
          :options="visibleRangeList"
          :props="visibleRangeProps"
          @change="changeRange"
        ></el-cascader>
        <!-- <el-select
                    style="width:100%"
                    v-model="dialogForm.visibleRange"
                    placeholder="请选择"
                >
                    <el-option :label="item.name" v-for="(item,index) in visibleRangeList" :key="index" :value="item.label"></el-option>
                </el-select> -->
      </el-form-item>
      <el-form-item class="mt0" v-if="!readonly">
        <el-button
          type="primary"
          :loading="saveLoading"
          :disabled="saveLoading"
          @click="saveThirdDirectory('dialogForm')"
          >保 存</el-button
        >
        <el-button @click="goBack()" class="reset">返 回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { testFun } from "@/utils/validate";
export default {
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持大于或等于0的数字"));
      } else {
        callback();
      }
    };
    return {
      dialogForm: {
        parentId: "",
        directoryName: "",
        description: "",
        isEnable: "1",
        visibleType: "1",
        visibleRange: [],
        visibleRangeName: [],
        sort: 0,
        visibleParent: "",
      },
      rules: {
        parentId: [
          { required: true, message: "请选择父级节点", trigger: "change" },
        ],
        directoryName: [
          { required: true, message: "请输入目录名称", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
        isEnable: [
          { required: true, message: "请选择是否启用", trigger: "change" },
        ],
        visibleType: [
          { required: true, message: "请选择目录权限", trigger: "change" },
        ],
        visibleRange: [
          { required: true, message: "请选择可见范围", trigger: "change" },
        ],
        sort: [{ required: false }, { validator: checkNum, trigger: "blur" }],
      },
      readonly: false,
      title: "",
      visibleRangeList: [],
      parentList: [],
      treeProps: {
        value: "id",
        label: "directoryName",
        children: "child",
      },
      visibleRangeProps: {
        value: "label",
        label: "name",
        children: "child",
        multiple: true,
        // checkStrictly: true
      },
      loadingDetail: false,
      metaTitle: "",
      saveLoading: false,
      labelWidth: "120px",
    };
  },
  created() {
    if (this.$route.query.isEdit == 0) {
      // 添加
      this.title = "新增目录";
      if (this.$route.query.parentId) {
        this.dialogForm.parentId = parseInt(this.$route.query.parentId);
      }
      this.metaTitle = "新增目录管理";
      this.getPostByUser();
    } else if (this.$route.query.isEdit == 1) {
      // 编辑
      this.title = "编辑目录";
      this.getDetail();
      this.metaTitle = "编辑目录管理";
    } else if (this.$route.query.isEdit == 2) {
      // 查看
      this.title = "查看目录";
      this.readonly = true;
      this.getDetail();
      this.metaTitle = "查看目录管理";
    }
    let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList")
      ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList"))
      : null;
    if (chooseMenuInfoList) {
      chooseMenuInfoList.map((item) => {
        if (item.path == this.$route.path) {
          item.meta.title = this.metaTitle;
        }
      });
      let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList));
      breadCrumbList.shift();
      // console.log(breadCrumbList)
      this.$store.commit("common/getBreadcrumbList", breadCrumbList || []);
    }
    this.loadingDetail = true;
    this.getSecDirectory();
  },
  mounted() {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "80px";
    } else {
      this.labelWidth = (120 / 1920) * window.innerWidth + "px";
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 根据选择的目录权限请求不同接口
    handleChangeCategroyQx() {
      this.dialogForm.visibleRange = [];
      this.getPostByUser();
    },
    getPostByUser() {
      // 判断目录权限是岗位还是组织-20221031
      let api =
        this.dialogForm.visibleType === "1"
          ? this.$api.getPostForTree
          : this.$api.getDeptForTree;
      api()
        .then((res) => {
          this.loadingDetail = false;
          if (res.data.code == "0") {
            this.visibleRangeList = res.data.data;
            this.loadingDetail = false;
            // if(res.data.data.length>0){
            //     this.initPostByUser(res.data.data)
            // }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .finally(() => {
          this.loadingDetail = false;
        });
    },
    changeRange(val) {
      console.log(val);
      // this.$refs.cascader.dropDownVisible = false
      // console.log(val,this.$refs.cascader.getCheckedNodes()[0].label)
    },
    //改变输入按键事件
    getInputChange(value, type) {
      let resultValue = value.replace(/[^\w\.\/]/gi, "");
      this.dialogForm[type] = resultValue;
    },
    // 初始化可见范围数据 isPost不可选
    initPostByUser(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].isPost == 0) {
          arr[i].disabled = true;
        }
        if (arr[i].child) {
          this.initPostByUser(arr[i].child);
        } else {
          arr[i].child = undefined;
        }
      }
    },
    getSecDirectory() {
      this.$api.getSecDirectory().then((res) => {
        if (res.data.code == "0") {
          this.parentList = [];
          // this.parentList = res.data.data
          if (res.data.data) {
            res.data.data.forEach((e) => {
              if (e.child) {
                this.parentList.push(e);
              }
            });
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    filterName(arr, lebalArr) {
      for (let index = 0; index < lebalArr.length; index++) {
        const element = lebalArr[index];
        this.filterName(arr, element);
      }
      if (typeof lebalArr != "string") {
        return;
      }
      for (let index = 0; index < arr.length; index++) {
        const e = arr[index];
      }
      arr.forEach((e) => {
        if (e.label == lebalArr) {
          this.filterName(e.child, lebalArr);
        }
      });
      // arr.filter(e=>e.label == lebalArr)
    },

    saveThirdDirectory(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          testFun(this.saveThirdDirectoryApi);
        }
      });
    },

    saveThirdDirectoryApi() {
      let parmas = JSON.parse(JSON.stringify(this.dialogForm));
      // if(parmas.isEnable){
      //     parmas.isEnable = 1
      // }else{
      //     parmas.isEnable = 0
      // }
      // console.log(typeof  parmas.parentId == 'object')
      if (typeof parmas.parentId == "object") {
        parmas.parentId = parmas.parentId[parmas.parentId.length - 1];
      }
      if (typeof parmas.visibleRange != "string") {
        parmas.visibleRange = [];
        parmas.visibleRangeName = [];
        this.dialogForm.visibleRange.map((item, index) => {
          parmas.visibleRange.push(item[item.length - 1]);
          if (this.visibleRangeList) {
            parmas.visibleRangeName.push(
              this.$refs.cascader.getCheckedNodes()[index].label
            );
          }
        });
        parmas.visibleRange = parmas.visibleRange.join(",");
        parmas.visibleRangeName = parmas.visibleRangeName.join(",");
      }

      console.log(parmas);

      // this.filterName(this.visibleRangeList,this.dialogForm.visibleRange)
      // if(this.visibleRangeList){
      //     this.visibleRangeList.forEach(e=>{
      //         if(e.label == parmas.visibleRange){
      //             parmas.visibleRangeName = e.name
      //         }
      //     })
      // }
      console.log(this.dialogForm.visibleRange);
      parmas.directoryLevel = 3;
      let parntList = [];
      this.dialogForm.visibleRange.map((item) => {
        parntList.push(item.join("|"));
      });
      parmas.visibleParent = parntList.join(",");
      // parmas
      this.saveLoading = true;
      this.$api
        .saveOrUpdateDirectory(parmas)
        .then((res) => {
          console.log(res);
          if (res.data.code == "0") {
            this.$message.success("保存成功");
            this.goBack();
          } else {
            this.$message.error(res.data.msg);
          }
          this.saveLoading = false;
        })
        .catch((error) => {
          this.saveLoading = false;
        });
    },

    handleChange() {},
    getDetail() {
      this.loadingDetail = true;
      this.$api
        .threeDirectoryQueryOrUpdate(this.$route.query.id)
        .then((res) => {
          // this.loadingDetail = false
          if (res.data.code == "0") {
            // if(res.data.data.isEnable == '1'){
            //     res.data.data.isEnable = true
            // }else{
            //     res.data.data.isEnable = false
            // }
            this.dialogForm = res.data.data;
            // 根据目录权限请求不通接口，1 岗位 2 组织
            this.getPostByUser();
            if (this.dialogForm.visibleParent) {
              let allList = this.dialogForm.visibleParent.split(",");
              let chooseMoreFlag = allList.some((item) => {
                return item.indexOf("|") != -1;
              });
              let moreList = [];
              if (chooseMoreFlag) {
                allList.map((info) => {
                  let list = info.split("|");
                  moreList.push(list);
                });
                this.dialogForm.visibleRange = moreList;
              } else {
                this.dialogForm.visibleRange = [allList];
              }
              // this.dialogForm.visibleRange = this.dialogForm.visibleParent.split(',')
            }
          } else {
            this.loadingDetail = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.loadingDetail = false;
        });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      console.log(e);
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  padding: 34px 30px 30px 30px;
  .form-input {
    width: 100%;
  }
}
</style>
